<div>
    <svg version="1.1" id="L4" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 60 30" enable-background="new 0 0 0 0" xml:space="preserve">
        <circle fill="#fff" stroke="none" cx="10" cy="15" r="6">
            <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.1"/>    
        </circle>
        <circle fill="#fff" stroke="none" cx="30" cy="15" r="6">
            <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite" 
            begin="0.2"/>       
        </circle>
        <circle fill="#fff" stroke="none" cx="50" cy="15" r="6">
            <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite" 
            begin="0.3"/>     
        </circle>
    </svg>
</div>

<style lang="scss">
    div{
        display: inline-block;
        width: 4rem;
        vertical-align: middle;
    }

    svg{
        width: 100%;
        vertical-align: middle;
    }

</style>